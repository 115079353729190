module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"4fIuKYgHAO8RwJhTd2BQEF3nnpLdZi7X","devKey":"4fIuKYgHAO8RwJhTd2BQEF3nnpLdZi7X","trackPage":true,"trackPageImmediately":false,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0,"manualLoad":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","consent":"default","analytics_storage":"denied","ad_storage":"denied","ad_user_data":"denied","ad_personalization":"denied","wait_for_update":2000}},"routeChangeEventName":"gatsby_route_change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
